///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。
*/
.ec-productRole {
  @include container;
  max-width: $container-maxwidth2;

  /* デスクトップ時のみ左右2分割レイアウト */
  & &__grid {

    @include media_desktop {
      display: flex;
      justify-content: space-between;
    }
  }

  & &__gridCell {

    // スマホ用レイアウト
    @include media_sphone {

      &.cellLeft {
        // 画像ビジュアル枠をフル幅にするためネガティブマージン使用
        margin-left: $padding-sphone * -1;
        margin-right: $padding-sphone * -1;
      }

      &.cellRight {
        margin-top: 20px;
      }
    }

    // タブレット用レイアウト
    @include media_tablet {

      &.cellLeft {
        // 画像ビジュアル枠をフル幅にするためネガティブマージン使用
        margin-left: $padding-tablet * -1;
        margin-right: $padding-tablet * -1;
      }

      &.cellRight {
        margin-top: 30px;
      }
    }

    // デスクトップ用レイアウト（左右2分割）
    @include media_desktop {

      &.cellLeft {
        flex: 0 0 auto;
        width: 57%;
      }

      &.cellRight {
        flex: 1 1 auto;
        width: auto;
        margin-left: 40px;
        //overflow: hidden;
      }
    }
  }
}



/*
商品ビジュアル表示
*/
.ec-productRole__visual {

  @include media_sphone {
    position: relative;
    overflow: hidden;// ビジュアルナビが右端からスライドインしますがその動作中に横スクロールバーを出させないようにします
  }

  @include media_tablet {
    display: flex;
    justify-content: space-between;
  }

  @include media_desktop {
    display: flex;
    justify-content: space-between;
  }

  /* メインビジュアル格納枠 */
  .ec-productVisualMain {
    // キャプション（説明文）表示に必要
    position: relative;
    overflow: hidden;

    @include media_tablet {
      flex: 0 0 auto;
      width: 83.334%;
    }

    @include media_desktop {
      flex: 0 0 auto;
      width: 83.25%;
    }

    // slick矢印ボタンの色を変更したい場合（slickについては、_12.1.slider.scssにて共通定義しています）
    .slick-arrow {
      //color: #aaa;
    }
  }

  /* ビジュアルナビ */
  .ec-productVisualNav {

    /* デスクトップ・タブレット時はメインビジュアルの横にナビ（サムネイル）を配置して、このナビには縦スライド機能をつけています。
     * スマホ時は、全く違う挙動で、ナビ開閉ボタンによりナビがドロワーと同様にサイドインする仕様です。
     * レスポンシブ動作する観点において、あらかじめデスクトップ・タブレット用のナビと、スマホ用のナビをそれぞれ別に組み込んでおきレスポンシブで表示を切り替えています。
     * 完全変形レスポンシブをするには、スライド機能のON/OFFやレイアウト等の変形など、いささかロジックが煩雑になると思われましたのでそのようにしています。
     */

    /* スマホ用ナビ
     * スマホ用ナビは、ナビ開閉ボタンで表示をON/OFFします。
     * ここでは見た目の基本スタイリングを施し、後ほど、開閉ボタンのスタイリングと共に開閉動作のスタイリングをまとめます。
     */
    &.sphone {
      position: fixed;
      top: $sphone-header-height;// ヘッダー分、下げます
      left: 0;
      width: 100vw;
      height: calc(100vh - #{$sphone-header-height});// ヘッダー分、高さを削ります
      padding: 0 $padding-sphone;
      overflow-y: auto;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 90;

      .sideInNavShelf {
        @include shelfLayout(3, 3px, 3px);
        padding-top: 60px;// ナビ開閉ボタンが被らないスペースを確保
        padding-bottom: $padding-sphone;
      }

      @include media_tablet {
        display: none;
      }

      @include media_desktop {
        display: none;
      }
    }

    /* デスクトップ・タブレット用ナビ */
    &.desktop {
      flex: 0 0 auto;
      overflow: hidden;// prev,nextボタン隠ぺい用
      // 高さをメインビジュアルと同じにします→これはjs側（detail.twig内）で実装しています

      @include media_tablet {
        // 余白なくぴっちり配置します。メインとナビの高さが揃う割合にしています
        width: 16.666%;
      }

      @include media_desktop {
        // メインとナビの間に約4pxほどの余白が生まれる割合にしています
        width: 16.35%;
      }

      opacity: 0;
      visibility: hidden;

      /* slickの準備ができたら表示 */
      &.slick-initialized {
        opacity: 1;
        visibility: visible;
      }

      /* prev,nextボタンのスタイリングを上書き調整します */
      .slick-arrow {
        font-size: 18px;
        padding: 0;
        width: 100%;
        height: 30px;
        background-color: rgba(255, 255, 255, 0.5);
        color: #333;
        opacity: 1;

        &:hover {
          background-color: rgba(255, 255, 255, 0.85);
        }

        &.slick-prev {
          top: 0;
          left: 0;
          transform: translate3d(0,-100%,0);// ナビエリアにマウスオーバーしてから表示させるため隠しておきます

          i {
            transform: rotate(-90deg);
          }
        }

        &.slick-next {
          top: auto;
          bottom: 0;
          left: 0;
          transform: translate3d(0,100%,0);// ナビエリアにマウスオーバーしてから表示させるため隠しておきます

          i {
            transform: rotate(90deg);
          }
        }
      }

      /* ナビエリアにマウスオーバーしたらprev,nextボタンを表示します */
      &:hover .slick-arrow {
        transform: translate3d(0,0,0);
      }

      /* サムネイル */
      .slideThumb {
        border: none;
        cursor: pointer;
        @include imageHover;

        @include media_tablet {
          margin-bottom: 0px;
        }

        @include media_desktop {
          margin-bottom: 4px;
        }
      }

      @include media_sphone {
        display: none;
      }
    }
  }
}

/*
スマホ用
ビジュアルナビのサイドイン動作と、開閉ボタンのスタイリング
*/
.ec-productRole__visual {

  /* スマホ用ビジュアルナビはCLOSE時は右端にスライダした形でスタンバイして隠します */
  .ec-productVisualNav.sphone {
    transform: translate3d(100%,0,0);
    transition: .3s;
  }

  // 開閉ボタンの配置位置変数
  $toggleBtnPosTop: 15px;
  $toggleBtnPosRight: 15px;

  /* ビジュアルナビ開閉ボタン */
  .sideInNavToggleBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #fff;

    font-size: 12px;
    height: 2em;
    line-height: 1;
    border-radius: 1em;
    padding: 0 .75em;

    position: absolute;
    top:   $toggleBtnPosTop;
    right: $toggleBtnPosRight;
    //z-index: 91;

    // アイコン
    i {
      display: block;
      font-size: 1.1em;
      margin-right: 5px;
    }

    // カレント値/トータル値
    span {
      display: block;
    }

    // カレント値
    .currentNum {
    }

    // セパレート
    .ceparate {
      margin: 0 5px;
    }

    // トータル値
    .totalNum {
    }

    @include media_tablet {
      display: none;
    }

    @include media_desktop {
      display: none;
    }
  }

  /* ビジュアルナビOPEN時 */
  &.is-navOpen {

    /* 右端からスライドインします */
    .ec-productVisualNav.sphone {
      transform: translate3d(0,0,0);
    }
  }
}



/*
商品プロフィール（商品データ）

画像ビジュアル枠に対してこちらは右枠の（デスクトップ時）、商品データ枠になります。
*/
.ec-productRole {

  /* 商品プロフィール枠 */
  & &__profile {
  }

  /* 商品タイトル */
  & &__title {

    h1 {
      font-family: $ff-min;
      font-size: 1.6rem;
      font-weight: 500;
      margin: 1em 0;
    }
  }

  /* 通常価格 */
  & &__priceRegular {
    margin: 0 0 0.5em;

    .ec-price__label {
      font-size: 1rem;
    }

    .ec-price__price {
      font-size: 1rem;
    }

    .ec-price__tax {
      font-size: 0.8rem;
    }

    // 「.ec-price」の構造スタイリングは、_1.2.type.scssにて
  }

  /* 販売価格 */
  & &__price {
    margin: 0 0 1em;

    .ec-price__label {
      font-size: 1rem;
    }

    .ec-price__price {
      font-size: 1.5rem;
      font-weight: 500;
    }

    .ec-price__tax {
      font-size: 0.8rem;
    }

    // 「.ec-price」の構造スタイリングは、_1.2.type.scssにて
  }

  /* 詳細コメント */
  & &__description {
    font-size: 1.07rem;
    margin: 1em 0;
    line-height: 1.7;
    color: $clrDefaultThin;
    text-align: justify;

    @include media_desktop {
      font-size: 1.133rem;
      margin: 2em 0;
    }
  }

  /* 商品コード（商品タイトルの中で展開） */
  & &__code {
    font-size: 0.933rem;
    color: $clrDefaultThin;
    margin: 0.5rem 0;

    .product-code-default {
      font-family: $ff-en;
    }
  }

  // 属性タグ枠
  & &__tags {
    list-style: none;
    margin: 0.75rem 0;
    margin-left: -5px;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
  }

  // 属性タグ
  & &__tag {
    border: solid 1px #ddd;
    margin: 0;
    margin-left: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    font-size: 0.866rem;
    padding: 0.5em 0.75em;
    line-height: 1;
    white-space: nowrap;
    color: $clrDefaultThin;
  }

  /* 関連カテゴリ */
  & &__category {
    margin: 1rem 0;
    font-size: 0.933rem;

    .titleLabel {
      color: $clrDefaultThin;
    }

    // 商品一覧で使用した.ec-topicpathを利用して、関連カテゴリ用に調整
    .ec-topicpath {

      .ec-topicpath__item {

        &:first-child {
          &:before {
            //display: none;
            content: "・";
          }
        }

        /* 最後のli要素を太字 */
        &:last-child {

          a {
            font-weight: normal;
            pointer-events: auto;
          }
        }
      }
    }
  }

  /* アクション枠 */
  & &__actions {
    @include borderTop;
    margin: 2rem 0;
    padding-top: 2rem;
  }

  /* 規格枠 */
  & &__class {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.classCategory1 {
    }

    &.classCategory2 {
      margin-top: 10px;
    }

    .classLabel {
      margin: 0;
      margin-right: 1em;
      min-width: 6em;

      &:after {
        content: "：";
      }
    }

    .ec-select {

      select {
        max-width: 100%;
      }
    }
  }

  /* カートイン枠数量・カートイン・お気に入り） */
  & &__cartinRow {
    display: flex;
    align-items: center;
    margin-top: 1em;

    /* 数量タイトルラベル */
    .quantityLabel {
      margin: 0;
      margin-right: 1em;
      display: none;
    }

    /* 数量 */
    .ec-quantityRole {
      //本体「.ec-quantityRole」は、_3.1.inputText.scssにて
      margin-right: 10px;
    }

    /* カートインボタン・品切れ */
    .ec-productRole__btn {
      flex: 1 1 auto;// 伸縮
      max-width: 350px;// お気に入りボタンが無い場合を考慮して一応最大幅を定義

      // ボタン定義を上書きして調整
      button {
        height: $cartinRow-size;
        line-height: $cartinRow-size;
        width: 100%;
      }
    }

    /* お気に入りボタン */
    .ec-blockBtn--favorite {
      // 本体のボタン定義は、_2.1.buttonsize.scssにて
      margin-left: 10px;
    }

    // スマホ用調整
    @include media_sphone {
      flex-flow: row wrap;

      .quantityLabel {
        display: block;
      }

      .quantityLabel,
      .ec-quantityRole {
        margin-bottom: 1em;
      }
    }
  }

  /* シェアボタン */
  & &__share {
    margin-top: 2rem;

    .shareLabel {
      margin: 0;
      margin-right: .5em;
      display: inline-block;
    }

    .shareBtnList {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin-left: 3px;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 17px;// ※emを使うとフォントサイズに端数が出て中のアイコン位置がずれる様子なのでpx指定
          width: 2em;
          height: 2em;

          @include media_sphone {
            font-size: 1.1rem;// スマホ時は相対的フォントサイズで
          }

          background:#666;
          color: #fff;
          border-radius: 50%;
          cursor: pointer;
          transition: .3s;

          i {

            & + span {
              display: none;
            }
          }

          &:hover {
            text-decoration: none;
          }

          &.twitter:hover {
            background-color: $clr-twitter;
          }
          &.facebook:hover {
            background-color: $clr-facebook;
          }
          &.pinterest:hover {
            background-color: $clr-pinterest;
          }
          &.line:hover {
            background-color: $clr-line;
          }
          &.pocket:hover {
            background-color: $clr-pocket;
          }
          &.hatena:hover {
            background-color: $clr-hatena;
          }
        }
      }
    }
  }
}



/*
商品フリーエリア

フリーエリアのスタイリングになります。
*/
.ec-productRole {

  & &__freearea {
    margin-top: 30px;

    @include media_desktop {
      margin-top: 60px;
    }
  }
}


/*
商品詳細タブ切り替えコンテンツ

管理機能「フリーエリア」に、HTMLコードを記述するとタブ切り替えコンテンツを表示させることができます。

サンプルコンテンツの、table要素、dl-table要素、dlレスポンシブの表組みスタイリングは、_0.1.base.scssにて行っています。
*/
.ec-productDetailTab {

  /* タブ */
  & &__nav {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    margin-bottom: 2rem;
    @include borderBottom;

    li {
      font-size: 1rem;
      margin-right: 1.5em;
      padding: 0.25em 0;
      color: $clrDefaultThin;
      border-bottom: solid 2px transparent;
      cursor: pointer;
      transition: .2s;

      &:hover {
        border-color: #000;
      }

      &.is-active {
        color: $clrDefaultDark;
        border-color: #000;
      }
    }
  }

  /* コンテンツ枠 */
  & &__wrap {
  }

  /* コンテンツ */
  & &__content {
    display: none;

    &.is-active {
      display: block;
      @include fadeIn;
    }

    h2 {
      font-family: $ff-min;
      font-size: 1.4rem;
      font-weight: 500;
      margin: 0;
      margin-bottom: 1em;
    }
  }
}
