///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

フッターガイド、フッターナビ、フッターソーシャル、フッタータイトルで構成されています。

※ページコンテンツ本体（.ec-layoutRole__contents）とフッターとの余白は、`.ec-layoutRole__footer`に持たせてあります。
*/
.ec-layoutRole__footer {

  // ページコンテンツとフッターとの余白をスタイリングします
  // ※stickyフッター（ブラウザの底に吸着）のため「margin-top:auto;」をかけてありますので（_11.1.role.scss）余白はpadding-topを用います
  @include media_sphone {
    padding-top: $padding-sphone;
  }

  @include media_tablet {
    padding-top: $padding-tablet;
  }

  @include media_desktop {
    padding-top: 100px;
  }

  // トップページでは上余白を解除します
  @at-root body#page_homepage & {
    padding-top: 0;
  }
}

.ec-footerRole {
  background-color: $clrFooterBase;
  color: $clrFooterText;// メイン文字色

  @include media_sphone {
    font-size: 0.9285rem;
  }

  @include media_tablet {
    font-size: 0.8666rem;
  }

  @include media_desktop {
    font-size: 0.8666rem;
  }
}



/*
フッターガイド
*/
.ec-footerGuide {
  background-color: lighten($clrFooterBase, 12%);

  & &__inner {
    @include container;
    max-width: $container-maxwidth2;
    margin: 0 auto;
  }

  & &__wrap {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
  }

  & &__item {
    flex: 0 0 33.3%;

    // for IE11
    @include hack-ie11 {
      max-width: 33.3%;
    }

    display: flex;
    align-items: center;
    border-left: solid 1px hsla(0,0%,100%,0.2);

    &:first-child {
      border-left: none;
    }
  }

  & &__itemIcon {
    font-size: 2rem;
    margin-right: 20px;
  }

  & &__itemText {

    h3 {
      font-family: $ff-en_thin;
      font-weight: 400;
      font-size: 1.154em;
      color: #fff;
      margin: 0;
      margin-bottom: 0.5em;
    }

    p {
      margin: 0;
    }
  }

  @include media_sphone {
    padding-top: $padding-sphone;

    & &__item {
      flex-basis: 100%;
      padding: 0;
      border-left: none;
      margin-bottom: $padding-sphone;
    }

    & &__itemText {
      p {
        line-height: 1.3;
      }
    }
  }

  @include media_tablet {
    padding-top: $padding-tablet;
    padding-bottom: $padding-tablet;

    & &__wrap {
      // itemの両局左右の余白をプラマイ0に補うためのネガティブマージン
      margin-left: -20px;
      margin-right: -20px;
    }

    & &__item {
      padding: 0 20px;
      // アイコンとテキストを上下配置にするためflexボックスを解除します
      display: block;
    }

    & &__itemIcon {
      margin-bottom: .25em;
    }

    & &__itemText {
      p {
        line-height: 1.3;
      }
    }
  }

  @include media_desktop {
    padding-top: $padding-desktop;
    padding-bottom: $padding-desktop;

    & &__wrap {
      // itemの両局左右の余白をプラマイ0に補うためのネガティブマージン
      margin-left: -20px;
      margin-right: -20px;
    }

    & &__item {
      padding: 0 20px;
    }

    & &__itemIcon {
      font-size: 32px;
    }
  }
}



/*
フッターナビ
*/
.ec-footerNavi {
  @include container;
  max-width: $container-maxwidth2;

  & &__wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  /* 各カラム */
  & &__item {
    flex: 0 0 auto;

    h3 {
      font-family: $ff-en_thin;
      font-weight: 400;
      font-size: 1.5em;
      color: #fff;
      margin: 0;
      margin-bottom: 1em;
    }
  }

  /* リスト */
  & &__itemList {
    margin: 0;
    padding: 0;
    list-style: none;

    li {

      a {
        display: block;
        padding: 0.3em 0;
        color: inherit;
      }
    }
  }

  @include media_sphone {
    padding-top: $padding-sphone;

    & &__item {
      width: 100%;

      // 2個目以降のカラムに上余白を設けます
      &:nth-child(n+2) {
        margin-top: $padding-sphone;
      }
    }
  }

  @include media_tablet {
    padding-top: $padding-tablet;

    // 各カラムに設ける左右余白の値
    $gutter: 15px;

    & &__wrap {
      // itemの両局左右の余白をプラマイ0に補うためのネガティブマージン
      margin-left: $gutter * -1;
      margin-right: $gutter * -1;
    }

    & &__item {
      width: 50%;// 2等分
      padding: 0 $gutter;

      // 3個目以降のカラムに上余白を設けます
      &:nth-child(n+3) {
        margin-top: $padding-tablet;
      }

      // 区切り線（2個目、4個目のカラム）
      &:nth-child(2),
      &:nth-child(4) {
        border-left: solid 1px hsla(0, 0%, 100%, 0.15);
      }
    }
  }

  @include media_desktop {
    padding-top: $padding-desktop;

    // 各カラムに設ける左右余白の値
    $gutter: 20px;

    & &__wrap {
      // itemの両局左右の余白をプラマイ0に補うためのネガティブマージン
      margin-left: $gutter * -1;
      margin-right: $gutter * -1;
    }

    & &__item {
      width: 25%;// 4分割
      padding: 0 $gutter;

      // 区切り罫線（2個目以降のカラム）
      &:nth-child(n+2) {
        border-left: solid 1px hsla(0, 0%, 100%, 0.15);
      }
    }
  }
}


/*
CONTACT-US
フッターに配置される「CONTACT US」のスタイリングになります。
*/
.ec-contactUsRole {

  & &__address {
    margin: .75em 0;

    display: flex;
    align-items: flex-start;
    line-height: 1.35;

    i {
      display: block;
      font-size: 1.35em;
      opacity: 0.5;
      flex: 0 0 1em;
    }

    span {
      display: block;
      margin-left: .5em;
    }

    a {
      color: inherit;
    }
  }
}



/*
フッターカレンダー

EC-CUBE4.1.1より追加された「カレンダーブロック」になりますが、当テンプレートではフッターカレンダーとして使用しています。
標準でふた月分のカレンダーが表示されますが、当テンプレートではひと月ごとスライド表示させるようにしています。
*/

// フッターカレンダー
.ec-footerCalendar {
  width: 100%;
  color: inherit;
  position: relative;// slick領域外アローボタン用

  @include media_desktop {
    // カレンダー枠に最大幅を定義して左右中央配置とします
    width: 250px;
    margin: 0 auto;
    margin-top: 10px;
  }

  // slickコンテナ
  & &__slick {
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);// カレンダーの下に敷かれる下線

    // slick準備整うまでは非表示
    visibility: hidden;
    opacity: 0;
    transition: 1s;

    // slick準備完了で表示開始
    &.slick-initialized.slick-initialized {
      visibility: visible;
      opacity: 1;
    }
  }

  // slick領域外アローボタン（※positionによって、カレンダータイトル（〇年〇月）の上に被せる形にします）
  .slick-appendArrows {
    position: absolute;
    top: 0.15rem;
    left: 0;
    width: 100%;

    display: flex;
    justify-content: space-between;

    .slick-arrow {
      font-size: .66rem;//10px;
      width:  1.5em;
      height: 1.5em;
      line-height: 1.5;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 50%;

      i {
        display: block;
        color: #fff;
        font-size: 1.5em; // サイズ微調整
        padding-top: 0.05em;// 上下位置微調整
      }

      &:not(.slick-disabled) {
        cursor: pointer;
      }

      &.slick-disabled {
        opacity: 0.25;
      }
    }

    .slick-prev {

      i {
        transform: rotate(180deg);
      }
    }

    .slick-next {
    }
  }

  // カレンダーアイテム
  & &__item {
  }

  // カレンダータイトル
  & &__monthTitle {
    text-align: center;
    font-size: 1rem;
    line-height: 1;
  }

  // 脚注
  & &__footnote {
    font-size: 0.8rem;
    color: inherit;
    line-height: 1.2;
    margin-top: 1em;

    .holiday {
      display: inline-block;
      width: 15px;
      height: 15px;
      background-color: #888;
      margin-left: .25em;
      margin-right: .25em;
    }
  }
}


// カレンダー
.ec-calendar {
  border-top: solid 1px rgba(255, 255, 255, 0.2);// カレンダーの上に敷かれるborderライン
  // ※カレンダーの下に敷かれる下線は、月によってカレンダー高さは変わるので、カレンダーにではなく、slickコンテナ側に下線を入れる形にしています
  padding: .5rem 0;
  margin-top: .5rem;

  // カレンダー構成table
  table {
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    font-size: 0.8rem;

    @include media_desktop {
      // tableをわずかに左右拡大して、日・土のマスの端の余白をつぶし、枠いっぱいになるようにします（カレンダーの枠「.ec-footerCalendar」が250pxである前提で調整します）
      width: calc(100% + 16px);
      margin-left: -8px;
    }

    th, td{
      padding: 1px 0;// 行の高さに連携します
      text-align: center;
      vertical-align: middle;
      font-weight: 400;

      span {
        display: inline-block;
        width: 1.7em;
        line-height: 1.7;
        border: solid 1px transparent;
      }
    }
  }

  // 休日の表記
  & &__holiday {

    span {
      background-color: #888;
      color: #000;
    }
  }

  // 本日の表記
  & &__today {

    span {
      border-color: #888;
      color: #fff;
    }
  }
}



/*
フッター ソーシャルボタン
*/
.ec-footerSocial {
  @include container;
  max-width: 1680px;

  padding-top: 20px;

  & &__itemList {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
      display: inline-block;
      margin: 5px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        width: 1.8em;
        height: 1.8em;
        border-radius: 50%;

        &:hover {
          text-decoration: none;
          color: #fff;
        }
      }

      i {
        display: block;
      }

      /* 各ソーシャルボタン毎に色付け（スマホ・タブレット時のみ） */
      @media only screen and ( max-width: ($desktop - 1) ) {
        a {
          color: #fff;
        }
        &.facebook a {
          background-color: $clr-facebook;
        }
        &.twitter a {
          background-color: $clr-twitter;
        }
        &.instagram a {
          background-color: $clr-instagram;
        }
      }

      /* デスクトップ時はマウスオーバーで色が付くようにします */
      @include media_desktop {
        a {
          color: #fff;
          background-color: hsla(0, 0%, 100%, 0.2);
          transition: .2s;
        }
        &.facebook a:hover {
          background-color: $clr-facebook;
        }
        &.twitter a:hover {
          background-color: $clr-twitter;
        }
        &.instagram a:hover {
          background-color: $clr-instagram;
        }
      }
    }
  }
}



/*
フッタータイトル
*/
.ec-footerTitle {
  @include container;
  max-width: 1680px;

  font-family: $ff-en;
  text-align: center;
  line-height: 1.2;

  @include media_sphone {
    padding-top: ($padding-sphone / 2);
    padding-bottom: ($padding-sphone / 2);
  }

  @include media_tablet {
    padding-top: ($padding-tablet / 2);
    padding-bottom: ($padding-tablet / 2);
  }

  @include media_desktop {
    padding-top: ($padding-desktop / 2);
    padding-bottom: ($padding-desktop / 2);
  }

  & &__logo {
    font-size: 1.5em;
    color: #fff;
    margin-bottom: 0.5em;

    a {
      color: inherit;
    }
  }

  & &__copyright {
    font-size: 12px;
  }
}
