///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
カテゴリヘッド

商品一覧ページの「カテゴリヘッドコンテンツ」のスタイルになります。
*/
.ec-categoryHeadRole {
  @include container;
  max-width: $container-maxwidth2;

  // カテゴリヘッド画像
  & &__image {

    @include media_sphone {
      // ネガティブマージンにてフル幅に拡張
      margin-left: $padding-sphone * -1;
      margin-right: $padding-sphone * -1;
    }

    @include media_tablet {
      // ネガティブマージンにてフル幅に拡張
      margin-left: $padding-tablet * -1;
      margin-right: $padding-tablet * -1;
    }

    // 初期は非表示
    visibility: hidden;
    opacity: 0;
    transition: 1s;

    // 表示開始
    &.is-active {
      visibility: visible;
      opacity: 1;
    }
  }

  // カテゴリヘッドコンテンツ
  & &__content {

    @include containerMargin;

    @include media_desktop {
      margin-top: $padding-desktop;
      margin-bottom: $padding-desktop;
    }

    // 初期は非表示
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0,-1em,0);
    transition: 1s;

    // 表示開始
    &.is-active {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0,0,0);
    }

    // カテゴリタイトル
    .title {
      font-family: $ff-min;
      font-size: 1.6rem;
      font-weight: 400;
      margin: 0;
      margin-bottom: .75em;

      @include media_desktop {
        font-size: 1.86rem;
      }
    }

    // 説明文
    .description {
      font-size: 0.93rem;
      line-height: 1.8;
    }
  }
}
