///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
ボタン生成

mixinを利用して各パターンのボタンを生成します。
*/



/*
通常ボタン（インラインボタン）

インラインの要素としてボタンを定義出来ます。
*/
.ec-inlineBtn {
  @include btn-default;
}
.ec-inlineBtn--primary {
  @include btn-primary
}
.ec-inlineBtn--action {
  @include btn-action
}
.ec-inlineBtn--cancel {
  @include btn-cancel
}



/*
ブロックボタン（全幅）

ボタンサイズは em で指定するため、テキストサイズの変更でボタンサイズを変更できます。
*/
.ec-blockBtn {
  @include blockBtn-default;
}
.ec-blockBtn--primary {
  @include blockBtn-primary
}
.ec-blockBtn--action {
  @include blockBtn-action
}
.ec-blockBtn--cancel {
  @include blockBtn-cancel
}

/*
カートインボタン
*/
.ec-blockBtn--cartin {
 // ボタンは1種類のみなのでmixinを作らず直接、生成
  @include _btn($btn-cartin-color, $btn-cartin-bg, $btn-cartin-bg);
  display: block;
  width: 100%;
  height: $btn-height;
  line-height: $btn-height;
  padding-top: 0;
  padding-bottom: 0;

  &:before {
    font-family: "jeiafont";
    content: "\e900";
    margin-right: 0.5em;
    // 大きさ調整（font-sizeよりscale使った方が収まりがいい？）
    display: inline-block;
    transform: scale(1.4);
  }

  &:hover {
    // 黒ボタン
    //background-color: lighten($btn-cartin-bg, 15%);
    //border-color: lighten($btn-cartin-bg, 15%);

    // hover時、アクションボタンカラーにしてみる
    background-color: $btn-action-bg;
    border-color: $btn-action-bg;    
  }
}

/*
お気に入りボタン

お気に入りボタンのサイズは商品詳細ページの「カートイン枠（横一列）」に合わせて、$cartinRow-sizeで生成しています。
*/
.ec-blockBtn--favorite {
   display: block;

  i {
    display: block;
    margin: 0;

    width: $cartinRow-size;
    height: $cartinRow-size;;
    text-align: center;
    border-radius: 50%;
    background-color: #666;
    color: $btn-favorite-color;
    transition: .2s;

    position: relative;// ポップアップ用

    &:before {
      font-size: ($cartinRow-size * 0.5);// アイコンサイズ
      line-height: $cartinRow-size * 1.1;// 係数はアイコン位置微調整
    }

    /* ポップアップ */
    span {
      position: absolute;
      bottom: calc(100% + 12px);
      left: calc(50% - 18px);

      $pointChangeBreakPoint: 1650px;

      // ブラウザ幅が狭くなるとポップアップがはみ出すので右端に揃えます
      @media ( max-width: $pointChangeBreakPoint ) {
        left: auto;
        right: 0;
      }

      display: block;
      padding: 0.75em 1em;
      background-color: #333;
      color: #fff;
      font-size: 0.8rem;
      line-height: 1;
      white-space: nowrap;
      border-radius: 2px;
      
      opacity: 0;
      visibility: hidden;
      transform: translate3d(0,10px,0);
      transition: .2s;

      &:after {
        content: "";
        width: 0;
        height: 0;
        border: solid 8px transparent;
        border-top-color: #333;
        position: absolute;
        top: 100%;
        left: 10px;

        // ポップアップ右揃え時のくちばし位置調整
        @media ( max-width: $pointChangeBreakPoint ) {
          left: auto;
          right: 10px;
        }
      }
    }

    &:hover {
      background-color: $btn-favorite-bg;

      span {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0,0,0);            
      }
    }
  }

  &.added i {
    background-color: $btn-favorite-bg;
  }
}



/*
JEIA4010で使用する「Shop Collection」ボタンのスタイリングです
*/
.ec-shopCollectionBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;//100%;
  padding: 0 1em;
  height: 2.6em;
  border-radius: 1.3em;

  font-family: $ff-en_thin;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  color: #fff;
  overflow: hidden;

  @include media_desktop {
    font-size: 16px;
  }

  position: relative;

  // hoverアニメーションさせるための疑似要素を準備します
  &:before,
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  // 通常のボタン背景
  &:before {
    background-color: #C8AF75;
    z-index: -2;
    transition: .3s;
  }

  // hover時のボタン背景を待機配置
  &:after {
    background-color: #9D6357;
    z-index: -1;
    transform: translate3d(-100%,0,0);
    border-radius: 1.3em;// ボタン自体と同じ値
    transition: .3s;
  }

  // hover
  &:hover {
    text-decoration: none;
    color: #fff;

    // after疑似要素をスライドインさせます
    &:after {
      transform: translate3d(0,0,0);
    }

    // before疑似要素にも背景色を替えて柔らかく
    &:before {
      background-color: #9D6357;
    }
  }
}



/*
Read Moreボタン
*/
.ec-blockReadMoreBtn {
  display: block;
  max-width: 14em;
  padding: .75em 0;
  margin: 1em 0;
  font-family: $ff-min;
  font-size: .93rem;
  color: #000;
  line-height: 1;
  text-align: left;
  transition: .5s;
  position: relative;

  @include media_sphone {
    max-width: 10em;
  }

  // 矢印
  &:before,
  &:after {
    content: "";
    display: block;
    background-color: #000;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: .3s;
  }

  // 矢印を形成する下線パーツ
  &:before {
    width: 100%;
    height: 1px;
  }

  // 矢印を形成する斜め線パーツ
  &:after {
    width: 12px;
    height: 1px;
    transform-origin: right bottom;
    transform: rotate(40deg);
  }

  // hover
  &:hover {
    text-decoration: none;
    padding-left: .5em;
    color: inherit;

    &:after {
      transform: rotate(25deg);
      width: 15px;
    }
  }
}

/*
Read Moreボタン 中央配置
*/
.ec-blockReadMoreBtn--center {
  @extend .ec-blockReadMoreBtn;
  max-width: 14em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}



/*
全ての商品一覧を見るボタン

JEIA4007トップページ「おすすめ商品」で使用した「全ての商品一覧を見る」ボタンのスタイリングです。
*/
/*
.ec-blockAllBtn {
  display: block;
  margin: 1em auto;
  font-family: $ff-min;
  font-size: 1rem;
  line-height: 1;
  padding: .85em 1em;
  text-align: center;
  background-color: #201F25;
  color: #fff;
  border: solid 7px #E2E2E2;
  transition: .3s;

  @include media_desktop {
    margin-top: 40px;
    font-size: 16px;
  }

  &:hover {
    text-decoration: none;
    background-color: #b00;
    color: #fff;
  }
}
*/



/*
詳細ボタン

JEIA4007「日本酒コラム（eyecatch.twig）」で使用した「その他記事を見る」ボタンのスタイリングです。
背景画像に合わせて色を変更調整してください。
*/
/*
.ec-blockDetailBtn {
  @include blockBtn-default;
  margin: 0 auto;
  border: solid 1px #fff;
  background-color: rgba(255, 255, 255, 0.75);
  color: #000;
  font-family: $ff-min;

  &:hover {
    /*
    background-color: #000;
    border-color: #000;
    color: #fff;
    *---/
    background-color: #fff;
    border-color: #fff;
    color: #000;
  }

  @include media_sphone {
    width: 100%;
    margin-top: $padding-sphone;
  }

  @include media_tablet {
    width: 300px;
    margin-top: $padding-tablet;
  }

  @include media_desktop {
    width: 300px;
    font-size: 1.2rem;
    margin-top: $padding-desktop;
  }
}
*/
