///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
商品一覧ヘッダーナビ

商品一覧ページのカテゴリトピックパスや、検索結果、また並び替えフォームをまとめたコンポーネントになります。
*/
.ec-searchnavRole {
  @include container;
  max-width: $container-maxwidth2;

  @include containerMargin;

  @include media_desktop {
    margin-top: $padding-desktop;
    margin-bottom: $padding-desktop;
  }

  /*
  inner
  トピックパス枠（__topicspath）と表示件数＆並び替え枠（__infos）の2つのエリアをflexにて左右両極に配置
  */
  & &__inner {
    background-color: #f4f4f4;
    padding: 5px;
    padding-left: .75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* トピックパス枠 */
  & &__topicpath {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1rem;
  }

  /* 表示件数と並び替えフォーム枠 */
  & &__infos {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  /* 表示件数 */
  & &__counter {
    font-size: 0.933rem;
    margin-right: 1em;
  }

  /* 並び替えフォーム */
  & &__actions {

    select {
      background-color: #fff;
    }
  }

  @include media_sphone {
    & &__inner {
      // ネガティブマージンにてフル幅に拡張
      margin-left: $padding-sphone * -1;
      margin-right: $padding-sphone * -1;
      padding: 10px $padding-sphone;
      flex-flow: row wrap;
    }

    & &__topicpath {
      width: 100%;
      padding: 0;
      font-size: 0.9rem;
    }

    & &__infos {
      width: 100%;
      border-top: solid 1px #ddd;
      margin-top: 10px;
      padding-top: 10px;
    }
  }
  
  @include media_tablet {
    & &__inner {
      // ネガティブマージンにてフル幅に拡張
      margin-left: $padding-tablet * -1;
      margin-right: $padding-tablet * -1;
      padding: 10px $padding-tablet;
      flex-flow: row wrap;
    }

    & &__topicpath {
      width: 100%;
      padding: 0;
      font-size: 0.9rem;
    }

    & &__infos {
      width: 100%;
      border-top: solid 1px #ddd;
      margin-top: 10px;
      padding-top: 10px;
    }
  }
}

/*
トピックパス本体
*/
.ec-topicpath {
  line-height: 1;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  & &__item {

    // スマホ時などパスが長くて改行されてしまうケースを考慮
    white-space: nowrap;
    padding: 4px 0;

    a {
      color: inherit;
      @include hoverBottomLine;
    }

    /* 連結の「>」 */
    &:before {
      font-family: "jeiafont";
      content: "\e902";
      font-size: 0.85em;
      margin-left: 4px;
    }

    /* タイトルラベル */
    &.titleLabel {

      &:before {
        display: none;
      }
    }

    /* 検索キーワード */
    &.is-search {

      span {
        margin-left: 0.2em;// 微調整
      }
    }

    /* 最後のli要素を太字 */
    &:last-child {
      
      a,
      span {
        font-weight: 500;
        pointer-events: none;
      }
    }
  }
}



/*
子カテゴリ表示

商品一覧ページにおいて現在表示しているカテゴリに、子カテゴリがあればそれを一覧表示します。
*/
.ec-childCategoryRole {
  @include container;
  max-width: $container-maxwidth2;
  margin-top: 20px;
  margin-bottom: 20px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0.933rem;

    li {
      display: inline-block;

      &:before {
        font-family: "jeiafont";
        content: "\e91a";
        vertical-align: -1px;// 微調整
      }

      a {
        color: inherit;
        @include hoverBottomLine;
      }
    }

    li.titleLabel {
      font-weight: 500;

      &:before {
        display: none;
      }
    }
  }
}
