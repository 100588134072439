///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
ヘッダー＆ドロワー
*/



/*
ヘッダーフレーム構造

まずはヘッダーのフレーム構造について定義しています。
ヘッダーは横2列のフレーム構造になっております。

ヘッダー左フレーム
* ドロワー開閉ボタン（スマホ・タブレット時のみ表示）
* ヘッダータイトル
* ヘッダーナビ

ヘッダー右フレーム
* ヘッダーメニュー
*/
.ec-layoutRole__header {
  background-color: $clrHeaderBase;
}

/* .ec-headerRole フレーム構造（左右2分割） */
.ec-headerRole {
  @include container;
  max-width: 100%;
  display: flex;
  justify-content: space-between;

  & &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  & &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  // スマホ用
  @include media_sphone {
    height: $sphone-header-height;
    padding: 0;

    & &__right {
      padding-right: 10px;// 右余白
    }
  }

  // タブレット用
  @include media_tablet {
    height: $tablet-header-height;
    padding: 0;

    & &__right {
      padding-right: 10px;// 右余白
    }
  }

  // デスクトップ用
  @include media_desktop {
    height: $desktop-header-height;

    // 左右paddingを上書き調整します
    padding-left: 15px;
    padding-right: 30px;
  }
}



/*
ヘッダーナビ
*/
.ec-headerNav {
  font-size: 14px;
  color: $clrHeaderText;
  margin-left: 40px;

  @include media_sphone {
    display: none;
  }

  @include media_tablet {
    display: none;
  }

  &__item {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    > a {
      display: block;
      color: inherit;
      @include hoverBottomLine($clrHeaderText);
    }

    /* サブメニュー枠組み */
    &.has-submenu {
      position: relative;

      .submenu {
        position: absolute;
        top: 100%;
        left: 0;
        width: auto;
        min-width: 300px;
        padding-top: 14px;/*くちばし分の余白*/
        pointer-events: none;//サブメニューが消える間際、隣のメニューに被さるので干渉しないように
        z-index: 999;

        visibility: hidden;
        opacity: 0;
        transform: translate3d(0,-15px,0);
        transition: .2s;

        &__body {
          background-color: $clrHeaderPopupBg;
          color: $clrHeaderPopupText;
          padding: 15px;
          filter: drop-shadow(0 0 1px #ddd) drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));// 1段目は罫線的な縁取り、2段目は影

          position: relative;// くちばし用

          /* くちばし */
          &:after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            bottom: 100%;
            left: 15px;
            border: solid 10px transparent;
            border-bottom-color: $clrHeaderPopupBg;
          }
        }
      }

      &:hover {
        .submenu {
          visibility: visible;
          opacity: 1;
          transform: translate3d(0,0,0);
          pointer-events: auto;//隣のメニューに干渉しないようにnoneとしていたので解除
       }
      }
    }
  }
}

/* サブメニュー内容（カテゴリツリー） */
.ec-categoryTree {
  font-family: $ff-default;
  font-size: 0.933rem;
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin: 0;
    padding: 0;
    border-bottom: solid 1px $clrHeaderPopupBorder;
    white-space: nowrap;

    position: relative;

    a {
      display: block;
      line-height: 1;
      padding: 1em .5em;
      transition: .2s;
      color: inherit;

      &:hover {
        text-decoration: none;
        background-color: $clrHeaderPopupHoverBg;
      }
    }

    ul.child {
      margin: 0;
      padding: 0;
      list-style-type: none;
      min-width: 10em;

      position: absolute;
      top: .5em;
      left: calc(100% - .5em);
      background-color: $clrHeaderPopupBg;
      color: inherit;
      padding: 15px;
      z-index: 9;
      border: solid 1px #ddd;

      visibility: hidden;
      opacity: 0;
      transition: .2s;
    }

    &:hover > ul.child {
      visibility: visible;
      opacity: 1;
    }
  }

}



/*
ドロワー開閉ボタン

ドロワー開閉ボタンはスマホ・タブレット時に表示されます。
*/
/* ドロワーボタン（サイドメニュー開閉ボタン） */
.ec-drawerButton {
  display: block;
  cursor: pointer;
  position: relative;

  @include media_sphone {
    width: $sphone-header-height;
    height: $sphone-header-height;
    padding: 10px;
  }
  @include media_tablet {
    width: $tablet-header-height;
    height: $tablet-header-height;
    padding: 12px;
  }
  @include media_desktop {
    display: none;
  }

  .burger {
    display: block;
    width: 100%;
    height: 2px;
    background-color: $clrHeaderBurger;
    position: relative;
    top: 50%;
    text-indent: -9999px;
    transition: .3s;

    &:before,
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: $clrHeaderBurger;
      position: absolute;
      left: 0;
      transition: .3s;
    }

    &:before {
      top: -8px;
    }

    &:after {
      bottom: -8px;
    }

    /* ドロワー（サイドメニュー）表示時 */
    body.have_curtain & {
      background-color: transparent;

      &:before {
        top: 0;
        transform: rotate(225deg);
      }

      &:after {
        bottom: 0;
        transform: rotate(135deg);
      }
    }
  }
}



/*
ヘッダータイトル
*/
.ec-headerTitle {
  display: block;

  a {
    transition: .3s;

    &:hover {
      opacity: 0.8;
    }
  }

  // スマホ用調整
  @include media_sphone {
    height: 100%;

    // ロゴを中央配置とします
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    a {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 45px;//ロゴの大きさを調整
        width: auto;
      }
    }
  }

  // タブレット用調整
  @include media_tablet {
    height: 100%;

    a {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 50px;//ロゴの大きさを調整
        width: auto;
      }
    }
  }

  // デスクトップ用調整
  /*
   * デスクトップ時はページスクロールされるとヘッダー高さを縮小させますので、縮小に伴い、
   * ヘッダーロゴ画像が連動して縮小されるように高さに合わせて幅が変動するように準備します。（※ヘッダー縮小の挙動スタイリングは当scssの最後の方で記述しています）
   * ロゴ画像自体の高さ（Sass変数）をmax-heightに設定しておき、ロゴ画像がそれ以上大きくならないようにしています。
   *
   * ロゴ画像のデザインにもよりますが、モバイル時のロゴの収まりも優先して画像生成して、デスクトップ時はそれに合わせて調整（上下中央配置）できた方がいいと思います。
   */
  @include media_desktop {
    height: 100%;

    a {
      display: flex;
      height: 100%;
      justify-content: flex-start;
      align-items: center;

      img {
        width: auto;
        height: 100%;
        max-height: $headerlogo-height;// ヘッダーロゴ画像の高さを設定
      }
    }
  }
}



/*
ヘッダーメニュー

ヘッダーメニューは、会員登録、ログインや、商品検索、カートといったユーザメニューで構成されています。
またマウスオーバーでボタンガイド的なポップアップが表示されるようにしています。
*/
.ec-headerMenu {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $clrHeaderText;

  & &__item {
    padding: 0 0.25em;

    @include media_desktop {
      padding: 0 0.5em;
    }

    > a {
      display: block;
      color: inherit;
      line-height: 1;
      padding: 7px 7px;
      border-radius: 18px;// アイコンサイズが20px + padding 7px + border 1px
      //border: solid 1px transparent;
      border: solid 1px $clrDrawerBorder;
      transition: .2s;

      &:hover {
        text-decoration: none;
        background-color: $clrHeaderMenuHoverBg;
        border-color: $clrHeaderMenuHoverBg;
        color: #fff;
      }
    }

    /* スマホ時に非表示とするボタン */
    &.hidden_mobile {
      @include media_sphone {
        display: none;
      }
    }


    /* 最後のitemは右余白を除去 */
    &:last-child {
      padding-right: 0;
    }
  }

  & &__itemIcon {
    font-size: 20px;
  }

  & &__itemText {
    display: none;// ボタンテキスト不要なら
    //line-height: 20px;// アイコンと同値にしておいたほうが無難

    @include media_sphone {
      display: none;
    }
  }
}

/* ポップアップ */
//「.ec-headerMenu__item」にclass「.has-popup」と追加し、下層にポップアップ用のHTML「.ec-headerMenu__itemPopup」を記述します。
//不要であれば削除してください。
.ec-headerMenu {

  & &__item.has-popup {
    position: relative;

    &:hover {
      .ec-headerMenu__itemPopup {
        visibility: visible;
        opacity: 1;
        transform: translate3d(0,0,0);
        pointer-events: auto;//隣のメニューに干渉しないようにnoneとしていたので解除
      }
    }
  }

  & &__itemPopup {
    position: absolute;
    top: 100%;
    right: calc(0.5em - 2px);// ヘッダーメニューボタンが持つ右余白分を寄せて、くちばしが中央あたりにくるよう微調整
    width: auto;
    min-width: 200px;
    padding-top: 15px;/*くちばし分の余白*/

    pointer-events: none;//サブメニューが消える間際、隣のメニューに被さるので干渉しないように
    z-index: 999;

    visibility: hidden;
    opacity: 0;
    transform: translate3d(0,-15px,0);
    transition: .2s;

    .itemPopupInner {
      background-color: $clrHeaderPopupBg;
      color: $clrHeaderPopupText;
      padding: 20px;
      text-align: center;
      white-space: nowrap;// 内容を改行させたくない場合
      filter: drop-shadow(0 0 1px #ddd) drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));// 1段目は罫線的な縁取り、2段目は影

      position: relative;// くちばし用

      /* くちばし */
      &:after {
        content: "";
        width: 0;
        height: 0;
        border: solid 10px transparent;
        border-bottom-color: $clrHeaderPopupBg;
        position: absolute;
        bottom: 100%;
        right: 10px;
      }
    }

    @include media_sphone {
      display: none;
    }

    @include media_tablet {
      display: none;
    }

    /* ポップアップ内のブロックボタン（ログアウトボタン）.ec-blockBtnを上書き調整します */
    .ec-blockBtn {
      font-size: inherit;
      height: 2.7em;
      line-height: 2.7em;
    }
  }
}



/*
商品検索ボックス

ヘッダーメニューにある虫眼鏡ボタンをクリックする事で表示される商品検索ボックスのコンポーネントです。

※当テンプレートでは商品検索ボックスはヘッダーのみに実装する考えです。モバイル時でも検索ボックスの開閉ボタンがヘッダーに常駐します。
*/
.ec-headerSearch {
  $SearchHeight: 36px;

  /* 検索ボックス開閉ボタン */
  & &__button {
  }

  /* 検索ボックス全体枠 */
  & &__body {
    background-color: $clrHeaderBase;
    color: $clrHeaderText;
    padding: 10px;
    padding-top: 0;

    @include media_desktop {
      background-color: transparent;
    }
  }

  /* 検索ボックス：カテゴリ&入力欄 レイアウト枠 */
  & &__wrap {
    display: flex;
    align-items: center;
    height: $SearchHeight;// 高さ
    border-radius: ($SearchHeight / 2);
    border: solid 1px $clrDrawerBorder;
    padding: 4px;
    overflow: hidden;

    @include media_desktop {
      //border-color: #d6d6d6;// トップ以外のページでは白背景なので薄い白線は破棄（デザインカンプの非実装）
    }
  }

  /* 検索ボックス：カテゴリ選択 */
  & &__category {
    border-right: solid 1px $clrDrawerBorder;

    // フォーム部品.ec-selectを検索ボックス用に調整
    .ec-select {
      margin: 0;

      .select_ui {

        // selectボタンに施した独自UIを上書き調整
        &:after {
          background-color: transparent;
          content: "\e902";
          transform: rotate(90deg);
          color: inherit;
          font-size: .7em;
          right: 3px;
        }

        select {
          display: block;
          font-size: 13px;
          height: 100%;
          padding: 0px 0.7em !important;
          background-color: transparent;
          color: inherit;
          border: none;
        }
      }
    }
  }

  /* 検索ボックス：入力欄 */
  & &__keyword {
    flex: 1 1 auto;
    position: relative;//検索ボタン配置用

    input[type="search"] {
      border: none;
      margin: 0;
      border-radius: 0;
      font-size: 16px;
      height: 100%;
      padding-left: 1em;
      padding-right: $SearchHeight;
      background-color: transparent;
      color: inherit;

      @include media_desktop {
        font-size: 13px;

        &::placeholder {
          color: $clrDrawerBorder;
        }
      }
    }
  }

  /* 検索ボタン */
  & &__keywordBtn {
    border: none;
    background: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    // ボタンサイズ
    width: $SearchHeight;
    height: $SearchHeight;
    font-size: 20px;
    color: inherit;
  }
}

/*
ヘッダーメニューに設置した商品検索コンポーネントのスタイリング
「商品検索ブロック」はレイアウト管理でドロワー等にも設置可能ですので、他の部位に設置した場合にスタイリングが汚染影響しないようにしておきます。
*/
.ec-headerMenu {

  /* 検索ボックス全体枠 */
  .ec-headerSearch__body {
    position: absolute;
    z-index: 998;
    // 閉状態
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0,-15px,0);
    transition: .3s;

    // 開状態
    &.is-active {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0,0,0);
    }
  }
}

// スマホ用調整
@include media_sphone {
  // 検索ボックスの表示position位置は.ec-headerRole基準
  .ec-headerRole {
    position: relative;
  }
  .ec-headerMenu {
    .ec-headerSearch__body {
      top: 100%;
      width: 100%;
      left: 0;
    }
  }
}

// タブレット用調整
@include media_tablet {
  // 検索ボックスの表示position位置は.ec-headerRole基準
  .ec-headerRole {
    position: relative;
  }
  .ec-headerMenu {
    .ec-headerSearch__body {
      top: 100%;
      width: 100%;
      left: 0;
    }
  }
}

// デスクトップ用調整
@include media_desktop {
  // デスクトップ時は開閉ギミック無しの出っぱなし状態

  // 開閉用ボタン不要
  .ec-headerSearch__button {
    display: none !important;
  }

  // 検索ボックスの表示position位置は.ec-headerMenu基準
  .ec-headerMenu {
    position: relative;

    .ec-headerSearch__body {
      top: 0%;
      right: calc(100% - 0.5em);// ヘッダーメニューのボタン（.ec-headerMenu__item）間隔分を差し引いています
      width: 200px;
      padding: 0;

      // デスクトップ時は開閉ギミック無しの出っぱなし状態
      visibility: visible;
      opacity: 1;
      transform: translate3d(0,0,0);
      transition: 0s;
    }
  }
}



/*
カートナビゲーション

カートインがあればカートアイコンに数量をバッジ表示します。
カートボタンにマウスオーバーでカートナビゲーション（カート内容詳細）を表示します。
*/
.ec-cartNavi {
  $parent: &;//親要素名の記憶
  position: relative;

  // 一般のヘッダーメニューと色を変えるデザインですので上書きします
  color: #fff;

  > a {
    // 一般のヘッダーメニューと色を変えるデザインですので上書きします
    border-color: #000;
    background-color: #000;
  }

  /* カート数バッジ */
  & &__badge {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $ff-en;
    font-size: 10px;
    line-height: 1;
    width: 1.6em;
    height: 1.6em;
    border-radius: 50%;
    background-color: #f55;
    color: #fff;
    pointer-events: none;

    position: absolute;
    top: -0.25em;
    right: -0.25em;
  }

  /* カート内容詳細ポップアップ枠組み */
  & &__body {
    position: absolute;
    top: 100%;
    right: 0;
    width: 320px;// 収まりが悪い場合は調整
    display: inline-block;

    padding-top: 14px;/*くちばし分の余白*/
    pointer-events: none;//サブメニューが消える間際、隣のメニューに被さるので干渉しないように
    z-index: 999;

    visibility: hidden;
    opacity: 0;
    transform: translate3d(0,-15px,0);
    transition: .2s;

    .ec-cartNaviWrap {
      background-color: $clrHeaderPopupBg;
      color: $clrHeaderPopupText;
      padding: 20px;
      filter: drop-shadow(0 0 1px #ddd) drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));// 1段目は罫線的な縁取り、2段目は影

      position: relative;// くちばし用

      /* くちばし */
      &:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: 100%;
        right: 10px;
        border: solid 10px transparent;
        border-bottom-color: $clrHeaderPopupBg;
      }
    }
  }

  &:hover {
    #{$parent}__body {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0,0,0);
      pointer-events: auto;//隣のメニューに干渉しないようにnoneとしていたので解除
    }
  }
}



/*
カートナビゲーションのポップアップ(カートイン在り)

カートに商品が追加されている場合のカートナビゲーションのスタイリングです。
*/
.ec-cartNaviIsset {
  font-size: 13px;

  & &__cart {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px $clrHeaderPopupBorder;
  }

  /* 商品画像 */
  & &__cartImage {
    flex: 0 0 90px;
    order: 2;
    position: relative;// カート削除ボタン配置長

    > a {
      display: block;
    }
  }

  /* カート削除ボタン */
  & &__cartDelete {


    a {
      // 削除ボタン
      @include closeBtnCircle(20px);

      position: absolute;
      top: -10px;
      right: -10px;
    }
  }

  /* 商品詳細 */
  & &__cartContent {
    flex: 0 1 auto;
    order: 1;
    padding-right: 10px;
  }

  /* 商品名 */
  & &__cartContentTitle {
    margin-bottom: 0.5em;
    font-weight: 700;
  }

  /* 商品規格 */
  & &__cartContentClass {
    margin: 0;
    margin-left: 10px;
    margin-bottom: 0.5em;
    font-size: 0.85em;
  }

  /* 商品価格 */
  & &__cartContentPrice {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 10px;

    .price {
      display: inline-block;
    }

    .tax {
      display: inline-block;
      font-size: 0.85em;
    }

    .quantity {
      display: inline-block;
      margin-left: 10px;
    }
  }

  & &__TotalPrice {
    margin: 0;
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;
    font-weight: 500;

    dt {
      font-weight: inherit;
    }
  }

  & &__action {
    margin-top: 30px;

    .ec-blockBtn--action {
      background-color: #000;
      color: #fff;
      border: none;
    }
  }
}



/*
カートナビゲーションのポップアップ(カートイン無し)

カートが空の状態でのカートナビゲーションのスタイリングです。
*/
.ec-cartNaviNull {
  font-size: 13px;

  & &__message {
    text-align: center;

    p {
      margin: 0;
    }
  }
}



/*
ドロワー

スマホ・タブレット時に出現するドロワー（サイドメニュー）コンポーネントです。

ここではドロワーの枠組みを定義しています。
EC-CUBE標準ではドロワーOPEN時はドロワー閉ボタンも表示されますが当テンプレートではヘッダーにあるドロワー開閉ボタンがありますので他の閉ボタンは排除しています。
*/
.ec-drawerRole {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  overflow-y: auto;
  overscroll-behavior-y: contain;// メインコンテンツ側のスクロール連鎖を抑止（非サポートブラウザあり）
  background-color: $clrDrawerBase;
  color: $clrDrawerText;

  visibility: hidden;
  transform: translate3d(-100%,0,0);
  transition: .3s;

  @include media_sphone {
    height: calc(100vh - #{$sphone-header-height});
    top: $sphone-header-height;
    padding-left: $padding-sphone;
    padding-right: $padding-sphone;
  }

  @include media_tablet {
    max-width: 400px;// ドロワーの最大幅
    height: calc(100vh - #{$tablet-header-height});
    top: $tablet-header-height;
    padding-left: $padding-tablet;
    padding-right: $padding-tablet;
  }

  @include media_desktop {
    display: none;
  }

  &.is-active {
    visibility: visible;
    transform: translate3d(0,0,0);
  }
}

/* ドロワー閉ボタン（※不使用） */
.ec-drawerRoleClose {
  display: none;
}

/* ドロワー背景カーテン */
.ec-overlayRole {
  position: fixed;
  width: 100%;
  left: 0;
  background: hsla(0,0%,0%,.75);
  z-index: 99;

  visibility: hidden;
  opacity: 0;
  transition: .4s;

  /* ドロワー同様、背景カーテンもヘッダーの高さ分だけ短くしておきます */
  @include media_sphone {
    height: calc(100vh - #{$sphone-header-height});
    top: $sphone-header-height;
  }

  @include media_tablet {
    height: calc(100vh - #{$tablet-header-height});
    top: $tablet-header-height;
  }

  @include media_desktop {
    display: none;
  }

  /* 表示状態 */
  @at-root body.have_curtain & {
    visibility: visible;
    opacity: 1;
  }
}



/*
ドロワーメニュー

ドロワーの中身をこちらでスタイリングしています。
中身のコンテンツはブロック「ログインナビ（SP)」（login_sp.twig）になります。このブロックの中にすべてのコンテンツを記述しています。

EC-CUBE標準だと検索、カテゴリ、各メニューがそれぞれブロックで分離されておりますが、当テンプレートではブロック1つで実装する形にしています。
*/
.ec-drawerMenu {
  font-size: 0.9285rem;
  //margin-bottom: 20px;// .ec-drawerMenuごとに上下余白を設ける場合

  // iOS Safariだと底にあるアクションバーがある分、タップできないので余白を設けておきます
  &:last-child {
    padding-bottom: 50px;
  }

  & &__item {
    border-bottom: dotted 1px $clrDrawerBorder;

    a {
      display: block;
      padding: 0.75em 0;
      padding-right: 2em;// 開閉ボタン分の余白
      color: inherit;
      position: relative;
    }
  }

  & &__itemText {
  }

  & &__itemIcon {
    display: inline-block;
    vertical-align: middle;
    //font-size: 1.2em;
    color: $clrDrawerIcon;
    margin-left: 5px;
  }

  & &__itemBadge {
    display: inline-block;
    font-size: 10px;
    line-height: 1.6em;
    width: 1.6em;
    height: 1.6em;
    text-align: center;
    border-radius: 50%;
    background-color: #f55;
    color: #fff;
    font-family: $ff-en;
    pointer-events: none;
  }

  /*
  カテゴリ折り畳み表示
  商品カテゴリとマイページカテゴリにて使用
  */
  & &__item.has-category {
    position: relative;// 開閉ボタン用
  }

  // ドロワーカテゴリ基本スタイリング
  & &__category {
    //padding-left: 1em;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        margin: 0;
        padding: 0;

        a {
          display: block;
          padding: .5em 0;
          padding-right: 2em;// 開閉ボタン分の余白
          color: inherit;
        }

        &.has-child {
          position: relative;// 開閉ボタン用
        }
      }
    }

    // 親カテゴリ
    ul.parent {
      display: none;
      margin-bottom: 1em;

      // キャレット追加
      > li > a {
        &:before {
          font-family: "jeiafont";
          content: "\e91a";
          color: $clrDrawerIcon;
        }
      }
    }

    // 子孫カテゴリ（入れ子になるごとに字下げされる）
    ul.child {
      margin-left: .75em;
      padding-left: .75em;
      border-left: solid 1px $clrDrawerBorder2;
      display: none;
    }

    // カテゴリ開閉ボタン
    .categoyOpenBtn {

      &:after {
        font-family: "jeiafont";
        content: "\e902";
        font-size: 1em;
        display: block;
        width: 2em;
        height: 2em;
        line-height: 2em;
        cursor: pointer;
        text-align: center;
        position: absolute;
        top: 0.5em;
        right: 0;
        transition: .2s;
      }

      // OPEN状態
      &.is-open {
        &:after {
          transform: rotate(90deg);
        }
      }
    }
  }

  /* ドロワーフッターナビ */
  &.footerNav {
    margin-top: 20px;// 上余白を設ける場合
    //font-size: 0.8em;
  }
}




/*
ヘッダー挙動 その１

当テンプレートではヘッダーをページ上部に固定させる仕様となっております。
デスクトップ時はページスクロールが発生するとヘッダーの高さを縮小させます。
*/
.ec-layoutRole {

  & &__header {
    position: sticky;
    top: 0;
    z-index: 1001;
    transition: .3s;

    // デスクトップ時はページスクロールがあった際にヘッダー高さを縮小させます（ヘッダー高さは「.ec-layoutRole__header」ではなく「.ec-headerRole」が持っています）
    @include media_desktop {

      .ec-headerRole {
        transition: .3s;

        // ページスクロール時
        body.scrolled & {
          height: ($desktop-header-height * 0.5);
        }
      }
    }
  }
}



/*
ヘッダー挙動 その２

デスクトップ時のみの挙動ですが、
トップページでは、トッププロモーションにヘッダーが被る形となり、ヘッダー背景は透過状態になります。
ただし、背景を透過する条件は、「ページがスクロールされていない」場合とします。
*/
@include media_desktop {
  body#page_homepage {

    .ec-layoutRole {
      // トップページのみヘッダーがトップスライダーと被るデザインなのでネガティブマージンします
      margin-top: $desktop-header-height * -1;
    }

    // 状態が「ページがスクロールされていない」場合のみヘッダー背景を透過とします
    &:not( .scrolled ) {

      .ec-layoutRole__header {
        background-color: transparent;

        // プロモーションの色合いとヘッダーの色合いが被って見づらい場合は、hover時に薄く背景色を敷いてください
        &:hover {
          background-color: rgba(255, 255, 255, 0.5);
        }

        // 黒系ヘッダーの場合
        @if $headerThemeDark {
          background-color: rgba($clrHeaderBase, 0.75);

          &:hover {
            background-color: $clrHeaderBase;
          }
        }
      }
    }
  }
}
