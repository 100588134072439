///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
おすすめ商品管理プラグイン

※当テンプレートではカスタマイズした「Block/recommend_product_block.twig」を使用しています。
*/
.ec-recommendProductRole {

  @include container;
  max-width: $container-maxwidth3;

  @include containerMargin;

  // prev,nextボタンの配置を外にはみ出し配置、モバイル時はslickコンテナの右上にまとめて配置、のアローアイコンを細い線で実装するスタイル
  @include slickArrowRecommendStyle_thin(1700px, 45px, #222);

  // 商品アイテム
  /*
   各商品アイテムにはclass「.ec-productItemRole」を指定しておりこれは商品一覧ページでの商品アイテムの見せ方と同じスタイルになります。
   これをベースに必要なCSSスタイリングを上書きしています。
   */
  .ec-productItemRole {

    // 商品説明（一覧）
    .ec-productItemRole__description {
      font-size: .8rem;
      line-height: 1.2;
    }

    // おすすめ商品コメント
    .ec-productItemRole__recommendComment {
      font-size: .8rem;
      line-height: 1.2;
    }
  }

  // 商品登録のない場合のコメント
  .no_product {
    font-size: 1rem;
    margin-top: 2rem;
    text-align: center;

    @include media_desktop {
      margin-top: $padding-desktop;
    }
  }
}
